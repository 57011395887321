<template>
  <div class="van-main license-list">
    <van-search
      v-model="searchForm.licenseNumber"
      class="van-main-search"
      shape="round"
      left-icon=""
      show-action
      placeholder="请输入许可证编号"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">
          搜索
        </div>
      </template>
    </van-search>
    <div class="van-refresh-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="onLoad"
        >
          <div v-for="item in list" :key="item.id" class="license-item">
            <van-cell title="许可证编号" :value="utils.isEffectiveCommon(item.licenseNumber)" />
            <van-cell title="原产地" :value="utils.isEffectiveCommon(item.placeOrigin)" />
            <van-cell title="检疫物名称" :value="utils.isEffectiveCommon(item.quarantineObjectsName)" />
            <van-cell title="HS编码" :value="utils.isEffectiveCommon(item.hsCode)" />
            <van-cell title="目的港" :value="utils.isEffectiveCommon(item.destinationPort)" />
            <van-cell v-if="item.limitedPeriodStar && item.limitedPeriodEnd" title="有效期限" :value="utils.dateFormat(item.limitedPeriodStart) - utils.dateFormat(item.limitedPeriodEnd)" />
            <van-cell v-else title="有效期限" value="-" />
            <van-cell>
              <van-button class="lf" plain round size="small" type="info" native-type="button" @click="detail(item.id)">
                查看详情
              </van-button>
            </van-cell>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <back-button />
  </div>
</template>

<script>
import BackButton from '@/components/back-button'
import { Dialog, Cell, CellGroup, Search, List, Divider, Button, PullRefresh } from 'vant'
export default {
  components: {
    BackButton,
    [Dialog.Component.name]: Dialog.Component,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [List.name]: List,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [PullRefresh.name]: PullRefresh
  },
  data () {
    return {
      searchForm: {
        licenseNumber: '',
        pageNum: 1,
        pageSize: 10
      },
      loading: false,
      finished: false,
      refreshing: false,
      finishedText: '没有更多了',
      tableList: {
        list: []
      },
      list: []
    }
  },
  created () {
  },
  methods: {
    onSearch () {
      this.searchForm.pageNum = 1
      this.finishedText = '没有更多了'
      this.getList()
    },
    getList () {
      this.api.license.listPage(this.searchForm).then(result => {
        this.tableList = result.data.value
        if (this.tableList.pages === 0 && this._.isEmpty(this.tableList.list)) {
          this.list = []
          this.finishedText = '暂无数据'
          this.finished = true
        } else {
          if (this.tableList.pageNum === 1) {
            this.list = result.data.value.list
          } else {
            this.list = this.list.concat(result.data.value.list)
          }
          this.loading = false
          if (this.tableList.pageNum < this.tableList.pages) {
            this.searchForm.pageNum += 1
          } else {
            this.finished = true
          }
        }
      }).catch(() => {
        this.finished = true
        this.refreshing = false
        this.finishedText = '暂无数据'
      }).finally(() => {
        this.refreshing = false
      })
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      this.loading = true
      this.searchForm.pageNum = 1
      this.getList()
    },
    onLoad () {
      this.getList()
    },
    detail (id) {
      this.$router.push({ name: 'licenseDetail', query: { id: id } })
    }
  }
}
</script>

<style lang="less" scoped>
.license-list {
  .van-list {
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .license-item {
    border:1px solid #ccc;
    margin: 10px 5px;
    border-radius: 4px;
    .van-cell__title{
      flex:0.4;
    }
    .van-cell__value{
      flex: 0.6;
      text-align: left;
    }
    .tc{
      text-align: center;
    }
    // button {
    //   position: absolute;
    //   height: 30px;
    //   top: calc(50% - 15px);
    //   right: 15px;
    // }
    // .van-divider {
    //   margin-top: 5px;
    // }

  }
}

</style>
